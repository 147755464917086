footer.section-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
  font-family: var(--fontfamily2);
  font-style: normal;

  section.footer-form {
    margin-bottom: 80px;

    form.updates-form {
      input.form-email {
        padding: 5px 10px;
        margin-right: 40px;
        background-color: transparent;
        border-bottom: 1px solid var(--color1);
        color: var(--color1);
        font-size: 26px;
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.1);
        }

        &:hover {
        }
      }
      button.form-submit {
        user-select: none;
        cursor: pointer;
        padding: 12px 20px;
        background-color: transparent;
        box-shadow: var(--boxshadow1);
        -webkit-box-shadow: var(--boxshadow1);
        -moz-box-shadow: var(--boxshadow1);
        color: var(--color3);
        font-size: 26px;
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(1);
        }
      }
    }
  }

  section.footer-socials {
    width: 100%;
    margin-bottom: 30px;

    ul.socials-list {
      display: flex;
      align-items: center;
      justify-content: center;

      li.social {
        margin: 0 15px;
        list-style-type: none;

        a.social-icon {
          user-select: none;
          color: var(--color1);
          font-size: 30px;
          transition: color 0.2s;

          &:visited {
            color: var(--color1);
          }

          &:hover {
            color: var(--color3);
          }

          &:active {
            color: var(--color1);
          }
        }
      }
    }
  }

  section.footer-copyright {
    opacity: 0.3;
    color: var(--color1);
    font-weight: 700;
    letter-spacing: 3px;
  }
}
