main.section-music {
  padding: 250px 0 0 0;

  h2.music-header {
    user-select: none;
    margin-bottom: 70px;
    color: var(--color1);
    font-family: var(--fontfamily1);
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
  ul.music-list {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 250px;

    li.music {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 250px;
      height: 100%;
      margin: 10px 30px;
      list-style-type: none;

      img.cover {
        user-select: none;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        box-shadow: var(--boxshadow1);
        -webkit-box-shadow: var(--boxshadow1);
        -moz-box-shadow: var(--boxshadow1);
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.1);
        }
      }

      h3.music-title {
        user-select: none;
        margin: 15px 0 10px 0;
        color: var(--color1);
        font-family: var(--fontfamily2);
        font-size: 38px;
        font-style: normal;
        font-weight: 200;
        text-align: center;
      }

      button.music-listen {
        user-select: none;
        cursor: pointer;
        padding: 12px 24px;
        margin-bottom: 25px;
        background-color: var(--color10);
        border-radius: 5px;
        box-shadow: var(--boxshadow1);
        -webkit-box-shadow: var(--boxshadow1);
        -moz-box-shadow: var(--boxshadow1);
        color: var(--color3);
        font-family: var(--fontfamily2);
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(1);
        }
      }
    }
  }
}
