article.section-lyrics {
  height: 70vh;
  color: var(--color1);
  font-family: var(--fontfamily1);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  margin: 100px 0;

  div.lyrics-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      h1.first-text {
        margin-left: 220px;
        text-align: left;
        overflow: hidden;
      }
      h1.second-text {
        margin-right: 220px;
        margin-top: 80px;
        text-align: right;
        overflow: hidden;
      }
    }
  }
}
