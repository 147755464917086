:root {
  --color1: #a19b97;
  --color2: #111111;
  --color3: #e2e2e2;
  --color4: #313131b9;
  --color5: #ffffff;
  --color6: #6e6e6e38;
  --color7: #00000012;
  --color8: #00000006;
  --color9: #757575;
  --color10: #00000042;
  --fontfamily1: "Protest Revolution", sans-serif;
  --fontfamily2: "Roboto", sans-serif;
  --boxshadow1: 10px 9px 50px 0px rgba(0, 0, 0, 0.75);
  --boxshadow2: 0px 0px 155px 31px rgba(0, 0, 0, 0.75);
  --gradientbackground1: linear-gradient(325deg, hsl(23deg 10% 75%) 0%, hsl(23deg 9% 88%) 87%, hsl(0deg 0% 100%) 100%);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
}

button,
input {
  border: none;
  outline: none;
}

div.App {
  position: relative;

  header.section-header {
    position: relative;
    height: 100vh;

    img.background-image {
      position: fixed;
      z-index: -1;
      width: 100%;
      height: 100vh;
      filter: saturate(0.5);
      object-fit: cover;
    }

    h1.main-header {
      user-select: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      color: var(--color1);
      font-family: var(--fontfamily1);
      font-size: 130px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
    }
  }
}

@media only screen and (max-height: 800px) {
  img.platform-logo {
    height: 34px !important;
  }
  div.aside-container {
    width: 270px !important;
  }
  a.platform-link {
    padding: 5px 15px !important;
  }
  a.platform-link:first-child {
    padding-top: 11px !important;
  }
  button.button-play {
    padding: 8px 20px !important;
  }
}

@media only screen and (max-width: 1500px) {
  ul.music-list {
    padding: 0 125px !important;
  }
}
@media only screen and (max-width: 1200px) {
  h1.main-header {
    font-size: 100px !important;
  }
  h2.music-header {
    font-size: 80px !important;
  }
  ul.music-list {
    padding: 0 80px !important;
  }
  h1.first-text {
    margin-left: 150px !important;
  }
  h1.second-text {
    margin-right: 150px !important;
  }
}

@media only screen and (max-width: 1000px) {
  h1.first-text {
    margin-left: 100px !important;
  }
  h1.second-text {
    margin-right: 100px !important;
  }
  input.form-email {
    font-size: 22px !important;
  }
  button.form-submit {
    font-size: 22px !important;
  }
}
@media only screen and (max-width: 900px) {
  h1.main-header {
    font-size: 85px !important;
  }
  h2.music-header {
    font-size: 70px !important;
  }
  h1.first-text {
    margin-left: 80px !important;
    font-size: 40px !important;
  }
  h1.second-text {
    margin-right: 80px !important;
    font-size: 40px !important;
  }
}
@media only screen and (max-width: 830px) {
  li.music {
    width: 200px !important;
  }
  h3.music-title {
    font-size: 32px !important;
  }
  button.music-listen {
    font-size: 15px !important;
    padding: 10px 14px !important;
  }
}
@media only screen and (max-width: 800px) {
  ul.music-list {
    padding: 0 50px !important;
  }

  li.music {
    width: 200px !important;
  }
  h3.music-title {
    font-size: 32px !important;
  }
}
@media only screen and (max-width: 650px) {
  h1.first-text {
    margin-left: 40px !important;
    font-size: 30px !important;
  }
  h1.second-text {
    margin-right: 40px !important;
    margin-top: 40px !important;
    font-size: 30px !important;
  }
  input.form-email {
    font-size: 18px !important;
  }
  button.form-submit {
    font-size: 18px !important;
  }

  li.social {
    margin: 0 10px !important;
  }
  a.social-icon {
    font-size: 25px !important;
  }
}
@media only screen and (max-width: 550px) {
  h1.main-header {
    font-size: 65px !important;
  }
  h2.music-header {
    font-size: 50px !important;
  }
  ul.music-list {
    padding: 0 20px !important;
  }
  input.form-email {
    font-size: 16px !important;
  }
  button.form-submit {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 500px) {
  form.updates-form {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    margin: 0 20px !important;
  }
  input.form-email {
    width: 100% !important;
    font-size: 16px !important;
  }
  button.form-submit {
    margin-top: 10px;
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 450px) {
  h1.first-text {
    margin-left: 15px !important;
    font-size: 28px !important;
  }
  h1.second-text {
    margin-right: 15px !important;
    font-size: 28px !important;
  }
}
@media only screen and (max-width: 400px) {
  h1.main-header {
    font-size: 55px !important;
  }
  li.social {
    margin: 0 7px !important;
  }
}
